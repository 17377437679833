<template>

  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">
      <button @click.prevent="refresh" class="btn btn-primary"  style="margin-left: 6rem; margin-right: 1rem; margin-bottom: 1rem; border-color: #00cfe8 !important; background-color: #00cfe8 !important;">Refresh</button>
      <table class="w-full border border-gray-300">
        <thead>
          <tr>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">SL</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center"></th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">User Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in offline" :key="index" class="bg-gray-100">
            <td class="pl-2 border-b border-l border-r border-gray-300 text-center">{{ index + 1 }}</td>
            <td class="pl-2 border-b border-l border-r border-gray-300 " ><span class="led red blink"></span></td>
            <td class="pl-2 border-b border-l border-r border-gray-300 " >{{ list }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
import { ISP_LIST, OFFLINE_USERS } from "@core/services/api";
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  data() {
    return {
      routerId: null,
      isp_id: JSON.parse(localStorage.getItem('userData')),
      name: null,
      price: null,
      total: 0,
      total_invoice: 0,
      offline: [],
      test: [],
      from_date: "",
      to_date: "",
      payment_status: null,
      payment_method: null,
    };
  },
  methods: {
    getOffLineData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + OFFLINE_USERS + this.routerId)
        .then((res) => {
          this.offline = res.data.data.offline_users;
          
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.offline = [];
          }
        });
    },
    refresh(){
        this.getOffLineData();
        
      },
  },
  mounted() {
    this.routerId = this.$route.params.routerId; // Get the routerId from route params
    this.getOffLineData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

@mixin ledColor($primaryColor, $secondaryColor) {
  background-color: $primaryColor;
}

@mixin blackLed($dimmedColor) {
  @include ledColor($dimmedColor, $dimmedColor);
}

@mixin led($name, $primaryColor, $secondaryColor, $dimmedColor, $blinkDuration) {
  &.#{$name} {
    @keyframes blink-#{$name} {
      from {
        @include blackLed($dimmedColor);
      }
      25% {
        @include blackLed($dimmedColor);
      }
      30% {
        @include ledColor($primaryColor, $secondaryColor);
      }
      75% {
        @include ledColor($primaryColor, $secondaryColor);
      }
      80% {
        @include blackLed($dimmedColor);
      }
      to {
        @include blackLed($dimmedColor);
      }
    }

    @include ledColor($primaryColor, $secondaryColor);

    &.blink {
      animation: blink-#{$name} $blinkDuration infinite;
    }
  }
}

.led {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);

  &.pulse {
    animation: pulse 4s infinite;
  }

  @include led('red', #f00, #600, #ac1f1f, 2.5s);   
}

</style>
<style>
/* HTML: <div class="loader"></div> */

@keyframes l5 {
  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>
